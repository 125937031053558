import { useStaticQuery, graphql } from "gatsby"

export const useContentfulForm = (lng, key) => {
  const {
    allContentfulFormularios: { nodes: contentfulForm },
  } = useStaticQuery(graphql`
    {
      allContentfulFormularios {
        nodes {
          key
          language {
            language
          }
          title
          title_form
          description {
            json
          }
          dropdown {
            ...FieldFragment
          }
          button
          spreadsheetId
          hubspotFormId
          isTermsConditions
        }
      }
    }
  `)

  const transform = {}

  contentfulForm.forEach(env => {
    if (env.language.language === lng && key === env.key) {
      Object.keys(env).forEach(key => {
        transform[key] = env[key]
      })
    }
  })
  return transform
}
