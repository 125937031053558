import React from "react"
import { Button } from "antd"

import { SectionInterest } from "../../MayInterestYou/MayInterestYou"
import card from "../../../assets/images/cart.png"

export const SectionThankYou = ({ title, text }) => {
  const dummyCards = [
    {
      id: 1,
      img: card,
      title: "Amet nisl semper semper cras maecenas proin diam vel ac. Sed.",
      text:
        "Varius integer interdum in sem tempus. Nullam urna amet habitasse hac nunc. Tincidunt donec sed metus fermentum. Pharetra, duis eget diam quam.",
    },
    {
      id: 2,
      img: card,
      title: "Amet nisl semper semper cras maecenas proin diam vel ac. Sed.",
      text:
        "Varius integer interdum in sem tempus. Nullam urna amet habitasse hac nunc. Tincidunt donec sed metus fermentum. Pharetra, duis eget diam quam.",
    },
    {
      id: 3,
      img: card,
      title: "Amet nisl semper semper cras maecenas proin diam vel ac. Sed.",
      text:
        "Varius integer interdum in sem tempus. Nullam urna amet habitasse hac nunc. Tincidunt donec sed metus fermentum. Pharetra, duis eget diam quam.",
    },
    {
      id: 4,
      img: card,
      title: "Amet nisl semper semper cras maecenas proin diam vel ac. Sed.",
      text:
        "Varius integer interdum in sem tempus. Nullam urna amet habitasse hac nunc. Tincidunt donec sed metus fermentum. Pharetra, duis eget diam quam.",
    },
    {
      id: 5,
      img: card,
      title: "Amet nisl semper semper cras maecenas proin diam vel ac. Sed.",
      text:
        "Varius integer interdum in sem tempus. Nullam urna amet habitasse hac nunc. Tincidunt donec sed metus fermentum. Pharetra, duis eget diam quam.",
    },
    {
      id: 6,
      img: card,
      title: "Amet nisl semper semper cras maecenas proin diam vel ac. Sed.",
      text:
        "Varius integer interdum in sem tempus. Nullam urna amet habitasse hac nunc. Tincidunt donec sed metus fermentum. Pharetra, duis eget diam quam.",
    },
  ]

  const dummyTabs = [
    { id: 1, name: "Casos de estudio" },
    { id: 2, name: "Blog" },
  ]

  return (
    <div className="SectionThankYou">
      <div className="SectionThankYou__container">
        <div className="SectionThankYou__banner">
          <div className="SectionThankYou__container--box">
            <h1>{title}</h1>
            <p>{text}</p>
            <div className="cont-btn">
              <Button className="btn btn--primary">Ir a home</Button>
            </div>
          </div>
          <div className="SectionThankYou__container--box box--right">
            {/* <img src={image} alt="pcLifters" className="image"/> */}
            <div
              className="image"
              style={{
                backgroundImage:
                  "url('https://res.cloudinary.com/arepa-tec/image/upload/v1599664996/lifters/Animaci%C3%B3n_Computador__1_-min_qlevxr.png')",
              }}
            />
          </div>
        </div>
      </div>
      <SectionInterest dummyCards={dummyCards} dummyTabs={dummyTabs} />
    </div>
  )
}
