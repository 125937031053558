import React, { useState, useEffect, Fragment } from "react"
import Slider from "react-slick"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"

import { wordpress as WordpressActions } from "../../services/Wordpress/WordpressActions"

const propsSlider = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
      }
    }
  ]
}

export const SectionInterest = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const tabs = [
    { key: "cases_study", title: t("general.study_cases") },
    { key: "blog", title: "Blog" },
  ]

  const categoriesCases = {
    pt: 117,
    es: 92
  }

  const { languageCurrent } = useSelector(state => state.language)
  const { articles, casesStudy } = useSelector(state => state.wordpress)
  const [tabSelected, setTabSelected] = useState(tabs[0].key)
  const [cardSelected, setCardSelected] = useState()

  useEffect(() => {
    if (!casesStudy && languageCurrent)
      dispatch(
        WordpressActions.getArticles({
          per_page: 5,
          order: "desc",
          orderby: "modified",
          _embed: true,
          page: 1,
          categories: categoriesCases[languageCurrent]
        }, 'casesStudy')
      )
  }, [languageCurrent])

  useEffect(() => {
    if (casesStudy && !articles)
      dispatch(
        WordpressActions.getArticles({
          per_page: 5,
          order: "desc",
          orderby: "modified",
          _embed: true,
          page: 1,
        })
      )
  }, [casesStudy])

  const handleTabActive = tabKey => {
    setTabSelected(tabKey)
  }

  const handleCardActive = item => {
    setCardSelected(item)
  }

  const Card = ({ title, description, image, button, item }) => {
    return (
      <div onClick={() => handleCardActive(item)}
        className={`slider-price--item ${cardSelected === item && "card--active"}`}
        data-aos="fade-up"
      >
        <img src={image} className="img" alt="imageCard" />
        <h3 className="title">{title}</h3>
        <p className="text">{description}</p>
        <a href={button.href} className="see-more">
          {button.label}
        </a>
      </div>
    )
  }

  return (
    <div className="section-interest">
      <div className="section-interest__container">
        <h1 className="section-interest__container--title">
          {t("general.may_interest_you")}
        </h1>
        <div className="tabs">
          <div className="tabs--center">
            {tabs?.map((item, index) => (
              <div
                key={index}
                onClick={() => handleTabActive(item.key)}
                className={`tabs--item ${tabSelected === item.key && "tabs--item--active"
                  }`}
              >
                {item.title}
              </div>
            ))}
          </div>
        </div>

        {tabSelected === "cases_study" && (
          <div className="cont-tabs">
            <div className="slider-price">
              <Slider {...propsSlider}>
                {casesStudy?.map((item, index) => (
                  <Fragment key={index}>
                    <Card
                      title={item.title.rendered}
                      description={item.acf?.descripcion_corta}
                      image={item._embedded["wp:featuredmedia"][0].source_url}
                      button={{
                        href: item.link,
                        label: t("button.watch_more"),
                      }}
                    />
                  </Fragment>
                ))}
              </Slider>
            </div>
            <div className="cards-res">
              {casesStudy?.map((item, index) => (
                <Fragment key={index}>
                  <Card
                    item={item.id}
                    title={item.title.rendered}
                    description={item.acf?.descripcion_corta}
                    image={item._embedded["wp:featuredmedia"][0].source_url}
                    button={{
                      href: item.link,
                      label: t("button.watch_more"),
                    }}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        )}

        {tabSelected === "blog" && (
          <div className="cont-tabs">
            <div className="slider-price">
              <Slider {...propsSlider}>
                {articles?.map((item, index) => (
                  <Fragment key={index}>
                    <Card
                      title={item.title.rendered}
                      description={item.acf?.descripcion_corta}
                      image={item._embedded["wp:featuredmedia"][0].source_url}
                      button={{
                        href: item.link,
                        label: t("button.watch_more"),
                      }}
                    />
                  </Fragment>
                ))}
              </Slider>
            </div>
            <div className="cards-res">
              {articles?.map((item, index) => (
                <Fragment key={index}>
                  <Card
                    item={item.id}
                    title={item.title.rendered}
                    description={item.acf?.descripcion_corta}
                    image={item._embedded["wp:featuredmedia"][0].source_url}
                    button={{ href: item.link, label: t("button.watch_more") }}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
