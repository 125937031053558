import React, { useState } from "react"
import ReactPlayer from "react-player"
import Slider from "react-slick"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../../components/Layout/LayoutPublic/LayoutPublic"
import FormContainer from "../../components/PageHomeDemo/FormContainer/FormContainer"
import { SectionThankYou } from "../../components/PageHomeDemo/SectionThankYou/SectionThankYou"
import back_mapa from "../../assets/images/back-mapa.jpg";
import { useSelector } from "react-redux"
import SEO from "../../components/seo"

const settings = {
  className: "center",
  centerMode: true,
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
}

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) =>
      node.content[0].value === "" ?
        <br /> : <p>{children}</p>
  }
}

const VideoDemoLanding = ({ data: { contentfulPaginaHomeDemo } }) => {

  const { languageISO639 } = useSelector(state => state.language)
  
  const [section, setSection] = useState("video_demo")

  if (!contentfulPaginaHomeDemo) return null

  const {
    seo,
    title,
    description,
    title_form,
    video,
    banner_background,
    allies,
    presentation_description,
    presentation11,
    presentation_group,
    meet_url_1_1,
    meet_group 
  } = contentfulPaginaHomeDemo

  return (
    <Layout fix="layout-fix">
      { seo && <SEO {...seo} lang={languageISO639} /> }
      {section === "video_demo" && (
        <div className="video-demo-landing">
          <div className="video-demo-landing__content">
            {banner_background && (
              <img src={back_mapa} className="imagen-mapa" alt="imagenMapa"/>
            )}
            <div className="bann">
              <div className="bann-cont">
                <div className="left">
                  <h1 className="left--title">{title}</h1>
                  <p className="left--text">{documentToReactComponents(description?.json, options)}</p>
                  <ReactPlayer
                    url={video.src}
                    className="video"
                    playing={false}
                    config={{
                      youtube: {
                        playerVars: {
                          controls: 0,
                          fs: 0,
                          modestbranding: 0,
                        },
                      },
                    }}
                  />
                  {allies && (
                    <div className="left__brands">
                      <Slider {...settings}>
                        {allies.map((item, index) => (
                          <div key={index} className="left__brands--card">
                            <img
                              src={item.photo}
                              className="img-brands"
                              alt="imgBrands"
                            />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  )}
                </div>
                <div className="right">
                  <FormContainer
                    title={title_form}
                    meet11={meet_url_1_1}
                    meetsGroup={meet_group}
                    presentationDescription={presentation_description}
                    presentation11={presentation11}
                    presentationGroup={presentation_group}
                    handlerSetSection={setSection}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {section === "thanksyou" && (
        <div>
          <SectionThankYou
            title="¡Gracias por registrarte!"
            text="Neque vulputate elementum faucibus eget at. Nulla faucibus nec scelerisque phasellus donec ut velit. Tellus a et sagittis, viverra. Habitant eu."
            image="https://res.cloudinary.com/arepa-tec/image/upload/v1599236990/lifters/contacto_2_2_xrdpgu.png"
          />
        </div>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageHomeDemo($locale: String) {
    contentfulPaginaHomeDemo(language: { language: { eq: $locale } }) {
      seo {
        ...SEOFragment
      }
      title
      description {
        json
      }
      title_form
      video {
        src
      }
      banner_background {
        src
      }
      allies {
        photo
      }
      presentation_description
      presentation11 {
        ...SectionFragment
      }
      presentation_group {
        ...SectionFragment
      }
      meet_url_1_1
      meet_group {
        ...LinkFragment
      }
    }
  }
`

export default VideoDemoLanding
