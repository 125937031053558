import React, { Fragment, useEffect, useState } from "react"
import { Checkbox } from "antd"
import moment from "moment"
import { ArrowLeftOutlined, LinkOutlined, CalendarOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

const pointClasificated = 40
const pointClasificatedMixted = 30

//Mayor a 40, muestra 1:1
//Entre 30 a 39, muestra 1:1 y group

const FormDemoCalendar = ({
  presentationDescription,
  presentation11,
  presentationGroup,
  title,
  params,
  handlerSetForm,
  meet11,
  meetsGroup
}) => {
  const { t } = useTranslation()

  const [cards, setCards] = useState([])
  const [searchParams, setSearchParams] = useState()
  const [presentationSelected, setPresentationSelected] = useState()

  useEffect(() => {
    if (params) {
      let paramsMeeting = new URLSearchParams('embed=true');
      paramsMeeting.append('email', params.email)
      paramsMeeting.append('firstName', params.firstname)
      paramsMeeting.append('lastname', params.lastname)
      paramsMeeting.append('prefix_number', params.prefix)
      setSearchParams(paramsMeeting)

      //Lógica de perfilamiento
      const updateCards = []
      if (params.profilePoint >= pointClasificated){
        updateCards.push(presentation11)
        setPresentationSelected(presentation11)
      } else if (params.profilePoint >= pointClasificatedMixted && params.profilePoint < pointClasificated) {
        updateCards.push(presentation11)
        updateCards.push(presentationGroup)
      }
      else {
        updateCards.push(presentationGroup)
        setPresentationSelected(presentationGroup)
      }

      setCards(updateCards)
    }
  }, [params])

  return (
    <div className="form-calender animate__fadeIn animate__animated">
      <div
        className={`form-calender__calender 
          ${presentationSelected?.key === "presentation_group" && "form-calender--active"}
        `}
      >
        {!presentationSelected && (
          <>
            <ArrowLeftOutlined className="icon-back" onClick={() => handlerSetForm(1)} />
            <h2 className="title">{title}</h2>
            <p>{presentationDescription}</p>
            <div className="cards">
              {cards?.map((item, index) => (
                <div key={index} className="card">
                  <div className="card--head">
                    <h3>{item.title}</h3>
                    <Checkbox
                      onChange={() => setPresentationSelected(item)}
                    />
                  </div>
                  <div className="card--body">
                    <span>{item.description?.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {presentationSelected && (
          <>
            <ArrowLeftOutlined className="icon-back" onClick={() => setPresentationSelected()} />
            <h2 className="title">{title}</h2>
            <p> {presentationDescription}</p>
            <div className="cards">
              <div>
                <div className="card">
                  <div className="card--head">
                    <h3>{presentationSelected.title}</h3>
                    <Checkbox checked />
                  </div>
                  <div className="card--body">
                    <span>{presentationSelected.description?.description}</span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {presentationSelected && presentationSelected.key === "presentation_11" && searchParams && (
        <div className="cal">
          <iframe id="inlineFrameExample"
            title="Inline Frame Example"
            width="330"
            height="600"
            src={`${meet11}?${searchParams.toString()}`}>
          </iframe>
        </div>
      )}
      {presentationSelected && presentationSelected.key === "presentation_group" && searchParams && (
        <div className="cal">
          <div className="cal-see-demo">
            <h4>{t('general.see_demo_group')}</h4>

            {meetsGroup.map((meet, i) => (
              <Fragment key={i}>
                <CalendarOutlined />
                <p>{moment(meet.date).format(`dddd DD - MM - YYYY`)}</p>
                <p><span>GTM </span> {moment(meet.date_start).format(`HH:mm A`)} - {moment(meet.date_final).format(`HH:mm A`)}</p>
                <LinkOutlined className="link" />
                <a>{meet.link?.link}</a>
              </Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default FormDemoCalendar
