import React from "react"

import { FormContentful } from '../../Forms/FormContentful/FormContentful'

export const FormGenerator = ({
  title,
  handlerThankYou,
  className,
  handlerFinish,
  buttonText
}) => {

  return (
    <FormContentful
      title={title}
      buttonText={buttonText}
      className={className}
      storageHubspot
      handlerFinish={handlerFinish}
      handlerThankYou={handlerThankYou}
      formKey="form_generator"
    />
  )
}