import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import FormDemoCalendar from "../FormDemoCalendar/FormDemoCalendar"
import { FormGenerator } from "../../Forms/FormGenerator/FormGenerator"

const FormContainer = ({
  title,
  presentationDescription,
  presentation11,
  presentationGroup,
  meet11,
  meetsGroup
}) => {
  const { t } = useTranslation()

  const [animation, setAnimation] = useState(1)
  const [isForm, setForm] = useState(2)
  const [paramsForm, setParamsForm] = useState()

  useEffect(() => {
    if (!paramsForm)
      setForm(1)
  }, [])

  const handlerFinish = (values, fields) => {
    let points = 0
    const exceptionAbsolute = ['BR', 'MX', 'EC']

    fields.forEach(field => {
      if (field.links) {
        field.links.forEach(link => {
          if (values[field.key] === link.label && link.photo) {
            let point = parseInt(link.photo)

            if(exceptionAbsolute.includes(values.prefix))
              point *= -1

            points += point
          }
        })
      }
    })

    setForm(2)
    setParamsForm({ ...values, profilePoint: points })
  }

  return (
    <>
      {isForm === 1 && (
        <div
          className={`FormVideoDemo 
          ${animation === 2 && "FormVideoDemo--animated"} 
          ${animation === 3 && "FormVideoDemo--animatedTwo"} 
          ${isForm === 2 && "FormVideoDemo--animated-spam"}`}
        >
          <FormGenerator
            title={title}
            buttonText={t('button.i_want_meet')}
            className="animate__animated animate__fadeIn resume-form"
            animation={animation}
            handlerSetAnimation={setAnimation}
            handlerFinish={handlerFinish}
          />
        </div>
      )}
      {isForm === 2 && (
        <div className="FormVideoDemo form--cal">
          <FormDemoCalendar
            title={title}
            meet11={meet11}
            meetsGroup={meetsGroup}
            params={paramsForm}
            handlerSetForm={setForm}
            presentationDescription={presentationDescription}
            presentation11={presentation11}
            presentationGroup={presentationGroup}
          />
        </div>
      )}
    </>
  )
}

export default FormContainer
