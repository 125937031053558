import React, { useEffect, useState } from "react"
import { Button, Checkbox, Form, Input, Radio, Select } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useTranslation } from "react-i18next"

import { addSpreedSheet } from '../../../@common/services/googleSheets'
import { HUBSPOT_EMAIL_TEST } from '../../../@common/environments/environment'
import { useContentfulForm } from "../../../contenful/querys/Forms"
import InputPhone from "../../InputPhone/InputPhone"
import { hubspot as HubspotActions } from "../../../services/Hubspot/HubspotActions"
import { modals as ModalsActions } from "../../../services/Modals/ModalsActions"

const { Option } = Select

export const FormContentful = ({
  formKey,
  storageGoogleSheets,
  storageHubspot,
  handlerThankYou,
  fieldsAdditional,
  className,
  title,
  alias,
  handlerFinish,
  buttonText
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const {
    success: { sendForm: hubspotSendFormSuccess }
  } = useSelector(state => state.hubspot)
  const { languageISO639 } = useSelector(state => state.language)
  const [fieldsHidden, setFieldsHidden] = useState({})
  const contentfulForm = useContentfulForm(languageISO639, formKey)

  useEffect(() => {
    if (hubspotSendFormSuccess) {
      if (handlerThankYou)
        handlerThankYou(true)
      if (handlerFinish)
        handlerFinish(form.getFieldsValue(), contentfulForm.dropdown)
      dispatch(HubspotActions.setSuccess('sendForm', false))
    }
  }, [hubspotSendFormSuccess])

  const onFinish = async values => {
    if (storageGoogleSheets) {
      //Buscar array de países
      let fieldCountries
      contentfulForm.dropdown.forEach(item => {
        const isCountry = item.links?.find(link => link.key === 'CO')
        if (isCountry && item.type !== 'phone')
          fieldCountries = item
      })

      const indexCountry = fieldCountries?.links.findIndex(countrie =>
        countrie.label === values[fieldCountries.key_google_sheet]
      )

      await addSpreedSheet(
        contentfulForm.spreadsheetId,
        (fieldsAdditional ? { ...values, ...fieldsAdditional } : values),
        indexCountry
      )
      handlerThankYou(true)
      form.resetFields()
    } else if (storageHubspot) {
      delete values.prefix
      delete values.agreement

      const params = process.env.CONTENFUL_ENVIRONMENT === 'master'? 
        values: 
        { ...values, email: HUBSPOT_EMAIL_TEST }

      dispatch(HubspotActions.sendForm(contentfulForm.hubspotFormId, null, params))
    }
  }

  const validateDependingFields = (field) => {
    field.dependent_fields?.forEach(dependentField => {
      const fieldDependent = form.getFieldValue(dependentField.key)
      if (!fieldDependent) {
        setFieldsHidden({ ...fieldsHidden, [field.key]: true })
      } else if (fieldsHidden[field.key]) {
        setFieldsHidden({ ...fieldsHidden, [field.key]: false })
      }
    })

    if (form.getFieldValue(field.key)) {
      const dependents = findDepenendencies(field.key)
      dependents?.forEach(fieldKey => {
        if (fieldsHidden[fieldKey])
          setFieldsHidden({ ...fieldsHidden, [fieldKey]: false })
      })
    }
  }

  const findDepenendencies = (key) => {
    const fieldsKeys = []
    contentfulForm.dropdown.forEach(field => {
      const fieldDepending = field.dependent_fields?.find(dependency => dependency.key === key)
      if (fieldDepending)
        fieldsKeys.push(field.key)
    })
    return fieldsKeys
  }

  const onChangeDropdown = (field) => {
    validateDependingFields(field)
  }

  const openPrivacyPolicy = () => {
    dispatch(ModalsActions.setModals('modalPolicyPrivacity', true))
  }


  const renderField = (field) => {
    if (fieldsHidden[field.key] === undefined)
      validateDependingFields(field)

    if (field.type === 'text' || field.type === 'email' || field.type === 'number')
      return (
        <div
          className="item"
          hidden={field.hidden || fieldsHidden[field.key]}
          key={field.key || field.key_google_sheet}
        >
          <label className="item--label">{field.label}</label>
          <Form.Item
            name={storageHubspot ? field.key : field.key_google_sheet}
            rules={[{ required: !field.hidden, message: t("form.error.required") }]}
          >
            <Input type={field.type} placeholder={field.placeholder} />
          </Form.Item>
        </div>
      )
    else if (field.type === 'dropdown')
      return (
        <div
          className="item"
          key={field.key || field.key_google_sheet}
          hidden={fieldsHidden[field.key] || (field.hidden && fieldsAdditional && fieldsAdditional[field.key])}
        >
          <label className="item--label">{field.label}</label>
          <Form.Item
            name={storageHubspot ? field.key : field.key_google_sheet}
            value={undefined}
            rules={[{ required: !field.hidden, message: t("form.error.required") }]}
          >
            <Select placeholder={field.placeholder} onChange={() => onChangeDropdown(field)}>
              {field.links?.map(option => (
                <Option
                  key={option.key}
                  value={option.label}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      )
    else if (field.type === 'phone')
      return (
        <div
          className="item"
          className="item-phone"
          key={field.key || field.key_google_sheet}
          hidden={fieldsHidden[field.key]}
        >
          <label className="item--label">{field.label}</label>
          <InputPhone
            FormParent={Form}
            formRef={form}
            prefixDetected
            style={{ width: "100%" }}
            prefixKey={storageHubspot ? "prefix" : "Prefijo"}
            phoneKey={storageHubspot ? field.key : field.key_google_sheet}
            onPhoneValid={() => validateDependingFields(field)}
          />
        </div>
      )
    else if (field.type === 'radio')
      return (
        <div
          className="item radios"
          key={field.key || field.key_google_sheet}
          hidden={field.hidden || fieldsHidden[field.key]}
        >
          <label className="item--label">{field.label}</label>
          <Form.Item
            className="group-radio"
            name={storageHubspot ? field.key : field.key_google_sheet}
            rules={[{ required: !field.hidden, message: t("form.error.required") }]}
          >
            <Radio.Group onChange={() => validateDependingFields(field)}>
              {field.links?.map(option => (
                <Radio
                  key={option.key}
                  value={field.key_google_sheet ? option.label : option.key}
                >
                  {option.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        </div>
      )
  }

  return (
    <Form
      name={formKey}
      form={form}
      className={`form-contentful ${className}`}
      onFinish={onFinish}
    >
      { title && <h2 className="title">{title}</h2>}
      { contentfulForm?.dropdown?.map((field, index) =>
        renderField(field, (index > 0 && contentfulForm.dropdown[index - 1], index))
      )}
      { contentfulForm?.isTermsConditions &&
        <div className="check">
          <Form.Item
            name="agreement"
            valuePropName="checked"
            className="checkeds"
            rules={[
              () => ({
                validator(rule, value) {
                  if (value) return Promise.resolve()
                  return Promise.reject(t("form.error.required"))
                },
              }),
            ]}>
            <Checkbox />
          </Form.Item>

          <label htmlFor="agreement" className="agreement">
            {t('general.confirm_accept')}{' '}
            <a target="_blank" onClick={openPrivacyPolicy}>{t('form.privacy_policies')}</a>
            {/*,{' '}
                  <a target="_blank" href={contentfulGeneral.terms_conditions}>{t('form.terms_conditions')}</a>*/}
                  .
          </label>
        </div>
      }
      <Form.Item className="cont-btn">
        <Button className="btn btn--primary" htmlType="submit">
          {buttonText || contentfulForm.button}
        </Button>
      </Form.Item>
    </Form>
  )
}